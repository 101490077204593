import request from '@/config/axios'
import { CommonStatusType } from '@/types/dataType'

export interface DictVO {
  id?: number
  name: string
  key: string
  status: CommonStatusType
  remark?: string
  createTime?: Date
  values?: DictValueVO[]
}

export interface DictPageReqVO extends PageParam {
  name?: string
  type?: string
  status?: number
  createTime?: string[]
}

export interface DictValueVO {
  id?: number
  label: string
  value: string | number | boolean | undefined
  dictId: number | undefined
  status?: CommonStatusType
  color: string
  backgroundColor: string
  remark?: string
  createTime?: Date
}

export const dictBasePath = '/system/dict'
export const basePath = `${dictBasePath}-value`

export const getDictValueListApi = (dictId: number) => {
  return request.get({ url: `${basePath}/${dictId}/list` })
}

export const getAllDictValueApi = () => {
  return request.get<Map<string, DictValueVO[]>>({ url: `${basePath}/all` })
}

// 新增字典数据
export const createDictValueApi = (data: DictValueVO) => {
  return request.post({ url: `${basePath}/create`, data })
}

// 修改字典数据
export const updateDictValueApi = (data: DictValueVO) => {
  return request.put({ url: `${basePath}/update`, data })
}

// 删除字典数据
export const deleteDictValueApi = (id: number) => {
  return request.delete({ url: `${basePath}/delete`, params: { id } })
}

// 查询字典（精简)列表
export const listSimpleDictApi = () => {
  return request.get({ url: `${dictBasePath}/list-all-simple` })
}

// 查询字典列表
export const getDictPageApi = (params: DictPageReqVO) => {
  return request.get({ url: `${dictBasePath}/page`, params })
}

// 查询字典详情
export const getDictApi = (id: number) => {
  return request.get({ url: `${dictBasePath}/get`, params: { id } })
}

// 新增字典
export const createDictApi = (data: DictVO) => {
  return request.post<number>({ url: `${dictBasePath}/create`, data })
}

// 修改字典
export const updateDictApi = (data: DictVO) => {
  return request.put({ url: `${dictBasePath}/update`, data })
}

/**
 * 删除字典
 * @param id  字典编号
 * @param deleteValues 是否删除与之关联的字典值
 * @returns
 */
export const deleteDictApi = (id: number, deleteValues: boolean) => {
  const data = {
    id,
    deleteValues
  }

  return request.delete({ url: `${dictBasePath}/delete`, data })
}

// 启用或者关闭
export const updateDictStatusApi = (id: number, status: CommonStatusType) => {
  const data = {
    id,
    status
  }
  return request.put<boolean>({ url: `${dictBasePath}/status`, data })
}
